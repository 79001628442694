<template>
  <div class="w-full mt-24">
    <the-top-bar :icon="'fas fa-users'" :title="'Korisnici'"></the-top-bar>
    <div class="px-9 pt-4 pb-5">
      <div
        class="w-full flex flex-row items-center justify-start py-4 border-b border-gray-200"
      >
        <div class="flex items-center py-0 px-4">
          <p class="text-base text-gray-600 dark:text-gray-400" id="page-view">
            {{ startPosition + 1 }} -
            {{
              endPosition > showAllUsers.length
                ? showAllUsers.length
                : endPosition
            }}
            od {{ showAllUsers.length }}
          </p>
          <a
            class="text-gray-600 dark:text-gray-400 ml-2 border-transparent border cursor-pointer rounded"
            @click="showBefore"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-left"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="15 6 9 12 15 18" />
            </svg>
          </a>
          <a
            class="text-gray-600 dark:text-gray-400 border-transparent border rounded focus:outline-none cursor-pointer"
            @click="showNext"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-right"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="9 6 15 12 9 18" />
            </svg>
          </a>
        </div>
        <div
          class="flex items-center px-4 py-2 rounded-xl mx-2 border border-gray-400"
        >
          <i class="fas fa-search mr-4 text-gray-400"></i>
          <input
            type="text"
            v-model="search"
            placeholder="pretraga..."
            class="text-base outline-none capitalize"
          />
        </div>
      </div>
      <div class="bg-white">
        <table class="w-full whitespace-nowrap">
          <thead class="sticky top-116 bg-white z-30">
            <tr
              tabindex="0"
              class="focus:outline-none h-16 w-full text-sm leading-none text-gray-800"
            >
              <th class="font-normal text-left pl-4">Ime Prezime</th>
              <th class="font-normal text-left pl-12">E-mail</th>
              <th class="font-normal text-left pl-12">Telefon</th>
              <th class="font-normal text-left pl-20">Verifikovan</th>
              <th class="font-normal text-left pl-20">Kreiran</th>
            </tr>
          </thead>
          <tbody
            class="w-full"
            v-for="user in this.showAllUsers.slice(this.startPosition, this.endPosition)"
            :key="user.userid"
          >
            <the-user
              :name="user.name"
              :lastname="user.lastname"
              :email="user.email"
              :createdAt="user.createdAt"
              :phone="user.phone"
              :verify="user.verify"
              :userid="user.userid"
            ></the-user>
          </tbody>
        </table>
      </div>
      <no-content
        :listLength="this.$store.getters.get_allUsers.length"
        :message="'Nemate korinika'"
      ></no-content>
    </div>
  </div>
</template>

<script>
import NoContent from "../partials/NoContent.vue";
import TheTopBar from "../partials/TheTopBar.vue";
import TheUser from "../partials/TheUser.vue";
export default {
  components: {
    TheUser,
    NoContent,
    TheTopBar,
  },
  data() {
    return {
      users: [],
      nousers: true,
      message: "",
      search: "",
      startPosition: 0,
      endPosition: 9,
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    showAllUsers() {
      if (this.search == "") {
        return this.$store.getters.get_allUsers;
      }
      return this.$store.getters.get_allUsers.filter((user) =>
        user.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async getUsers() {
      await this.$store.dispatch("getAllUsers");
    },
    showBefore() {
      if (this.startPosition < 1) {
        return;
      }
      this.startPosition -= 9;
      this.endPosition -= 9;
    },
    showNext() {
      if (this.endPosition > this.showAllUsers.length || this.endPosition == this.showAllUsers.length) {
        return;
      }
      this.startPosition = this.endPosition;
      this.endPosition += 9;
    },
  },
};
</script>
