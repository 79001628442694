<template>
  <tr
    tabindex="0"
    class="focus:outline-none h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
  >
    <td class="pl-4 cursor-pointer">
      <div class="flex items-center">
        <div class="w-10 h-10">
          <img
            class="w-full h-full"
            src="https://cdn.tuk.dev/assets/templates/olympus/projects.png"
            alt="UX Design and Visual Strategy"
          />
        </div>
        <div class="pl-4">
          <p class="font-medium">{{ this.user.lastname }}</p>
          <p class="text-xs leading-3 text-gray-600 pt-2">
            {{ this.user.name }}
          </p>
        </div>
      </div>
    </td>
    <td class="pl-12">
      <p class="text-sm leading-none text-gray-800">{{ this.user.email }}</p>
      <!-- <div class="w-24 h-3 bg-gray-100 rounded-full mt-2">
        <div class="w-20 h-3 bg-green-progress rounded-full"></div>
      </div> -->
    </td>
    <td class="pl-12">
      <p class="font-medium">{{ this.user.phone }}</p>
      <!-- <p class="text-xs leading-3 text-gray-600 mt-2">
                5 tasks pending
              </p> -->
    </td>
    <td class="pl-20">
      <p class="font-medium">
        <span
          v-if="this.user.verify == 0"
          class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-yellow text-gray-500"
        >
          Nije Verifikovan
        </span>
        <span
          v-else
          class="px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-green-300 text-white"
        >
          Verifikovan
        </span>
      </p>
      <!-- <p class="text-xs leading-3 text-gray-600 mt-2">$4,200 left</p> -->
    </td>
    <td class="pl-20">
      <p>{{ this.postedOn(this.user.createdAt) }}</p>
      <!-- <p class="text-xs leading-3 text-gray-600 mt-2">34 days</p> -->
    </td>
    <td class="px-7 2xl:px-0">
      <button
        class="p-2 rounded-md focus:outline-none ml-7"
        @click="areYouSure"
      >
        <i class="fas fa-trash-alt transition-colors hover:text-red-500"></i>
      </button>

      <div class="fixed inset-0 w-full h-full z-50" v-if="showModal">
      <div
        class="fixed inset-0 bg-trans flex items-center justify-center"
      >
        <div class="w-auto mx-auto shadow-2xl py-5 px-5 bg-gray-100 rounded-md">
          <div class="flex pb-3 items-center">
            <div class="text-gray-600">
              <i class="far fa-trash-alt text-xl text-red-400"></i>
            </div>
            <p class="text-lg text-gray-800 font-semibold pl-2">
              Obrisi korisnika?
              
            </p>
          </div>
          <p class="text-sm text-gray-600 pb-4 font-normal">
            Da li ste sigurni da zelite da obrisete <span class="capitalize font-bold">{{name}} {{lastname}}</span>? 
          </p>
          <br/>
          <div class="flex justify-start">
            <button
              @click="deleteUser(userid)"
              class="focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs mr-5"
            >
              Obrisi
            </button>
            <button
              @click="areYouSure"
              class="focus:outline-none bg-yellow transition duration-150 ease-in-out hover:bg-yellow-light rounded text-white px-3 py-2 text-xs"
            >
              Odustani
            </button>
          </div>
        </div>
      </div>
    </div>
    </td>
  </tr>
</template>

<script>
import TimeHandler from "../../../middleware/TimeHandler";
export default {
  props: [
    "name",
    "lastname",
    "email",
    "phone",
    "createdAt",
    "verify",
    "userid",
  ],
  data() {
    return {
      user: {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
        phone: this.phone,
        createdAt: this.createdAt,
        verify: this.verify,
        userid: this.userid,
      },
      showModal: false,
      show: null,
    };
  },
  methods: {
    postedOn(time) {
      return TimeHandler.SET_DATE(time);
    },
    areYouSure() {
      this.showModal = !this.showModal
    },
    deleteUser(){
      this.$store.dispatch("deleteUserByEmail", this.user.userid);
      this.showModal = false;
    }

  },
};
</script>

<style></style>
